<template >
  <div name="assessment">

    <button
      v-if="classSelected"
      @click="setAsSilver()"
      type="button"
      class="publish-button hover:bg-orange-400 bg-orange-300 ml-4 mb-4 text-xs"
    >
      Set all as Silver
    </button>

    <transition name="fade">
      <div class="loading" v-show="loading">
        <span class="fa fa-spinner fa-spin"></span> Loading
      </div>
    </transition>

    <div v-if="errored" class="empty">
      <p>
        We are not able to retrieve this information at the moment, please try
        back later
      </p>
    </div>

    <div
      class="grid grid-cols-1 lg:grid-cols-2 gap-3 bg-white"
      v-if="componentLoaded"
    >
      <div v-for="pupil in pupils" :key="pupil.identifer">
        <table
          class="
            border-l-2
            border-r-2
            border-solid
            border-list-grey
            border-blue-100
            border-b-8
            rounded
          "
        >
          <thead class="bg-list-grey font-bold text-base text-blue-500">
            <td colspan="6">{{ pupil.name }}</td>
          </thead>
          <tbody>
            <tr class="bg-list-grey">
              <td colspan="2">&nbsp;</td>
              <td v-for="grade in settings.gradeList" class="text-xs">
                {{ grade.value }}
              </td>
            </tr>
            <tr v-for="row in criteriaList" class="odd:bg-gray-100">
              <td class="text-xs">{{ row.value }}</td>
              <td
                v-for="grade in gradeTitles"
                class="text-xs"
                @click="setAssessment(pupil, row, grade)"
              >
                <button
                  v-if="grade.value == 'n/a'"
                  type="button"
                  class="
                    border-none
                    col-span-1
                    pl-1
                    pr-2
                    p-1
                    rounded
                    text-xs
                    font-medium
                    text-gray-300
                    hover:text-blue-200
                  "
                >
                  <i class="pr-1 text-xl fas fa-times"></i>
                </button>
                <button
                  v-else
                  type="button"
                  class="
                    px-1
                    p-1
                    rounded
                    text-2xl
                    lg:mr-1
                    xl:mr-5
                    text-white
                    icon
                    font-bold
                  "
                  v-bind:class="setStyle(pupil, row, grade)"
                ></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

  <script>
import { mapGetters } from "vuex";
import { SchoolService, BaseService } from "@/services/school.service";
import { calendarYearList } from "@/assets/js/utils";

export default {
  name: "assessment",
  props: {
    course: Object,
    pupils: Array,
    classSelected: {type: Boolean, required: true},
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      currentAccess: "currentAccess",
      settings: "settings",
    }),
    componentLoaded() {
      return this.loading == false;
    },
    criteriaList() {
      if (this.settings.assessmentCriteria.length > 0) {
        return this.settings.assessmentCriteria;
      }
    },
    gradeTitles() {
      let na = { value: "n/a", name: "", color: "text-orange-800" };
      const grades = [...this.settings.gradeList];
      grades.splice(0, 0, na);
      return grades;
    },
  },
  data: function () {
    return {
      errored: false,
      loading: true,
      assessments: [],
    };
  },
  created() {
    this.fetchCourseAssessment();
  },
  mounted() {},
  methods: {
    valueExists(pupil, criteria, grade) {
      let result = this.assessments.find(
        (a) => a.register_identifier === pupil.identifier
      );
      if (result == undefined) {
        return false;
      } else {
        switch (criteria.key) {
          case "performance":
            if (result.performance) {
              return result.performance.String == grade.key;
            }
            break;
          case "collaboration":
            if (result.collaboration) {
              return result.collaboration.String == grade.key;
            }
            break;
          case "competition":
            if (result.competition) {
              return result.competition.String == grade.key;
            }
            break;
          default:
            return false;
            break;
        }
      }
    },
    setStyle(pupil, criteria, grade) {
      if (this.valueExists(pupil, criteria, grade)) {
        let icon = grade.value == "greater" ? "fa-star" : "fa-check-circle";
        return grade.color + " " + "cursor-not-allowed" + " " + icon;
      } else {
        return "text-gray-300 fa-plus-circle " + "hover:" + grade.color;
      }
    },
    fetchCourseAssessment() {
      SchoolService.getAll("courses/" + this.course.id + "/assessments")
        .then((response) => this.buildAssessments(response.data))
        .catch((err) => {
          console.log("Assessments " + err);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    buildAssessments(data) {
      this.assessments = data;
    },
    setAsSilver() {
      this.$swal({
        title: "Are you sure you want to set all to Silver?",
        text: "This will change all data in current view!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, set as Silver!",
      }).then((result) => {
        if (result.value === true) {
          this.processSilver();
        }
      });
    },
    processSilver() {
      const vm = this;
      const silver = vm.settings.gradeList.find((f) => f.key === "silver");
      if (silver != undefined) {
        vm.pupils.forEach(function (p) {
          var payload = {};
          var result = vm.assessments.find(
            (a) => a.register_identifier === p.identifier
          );

          if (result == undefined) {
            payload = vm.newAssessmentObject(p);
          } else {
            payload = result;
          }

          payload.performance = { String: silver.key, Valid: true };
          payload.collaboration = { String: silver.key, Valid: true };
          payload.competition = { String: silver.key, Valid: true };

          vm.processAssessment(result, payload);

        });
      }
    },
    newAssessmentObject(pupil) {
      let calendarYear = calendarYearList().find((y) => y.current === true);
      var payload = {
        course_id: this.course.id,
        register_identifier: pupil.identifier,
        calendar_schoolyear: calendarYear.value,
        performance: { String: "n/a", Valid: true },
        collaboration: { String: "n/a", Valid: true },
        competition: { String: "n/a", Valid: true },
      };

      return payload;
    },
    setAssessment(pupil, criteria, grade) {

      var payload = {};
      var result = this.assessments.find(
        (a) => a.register_identifier === pupil.identifier
      );

      if (result == undefined) {
        payload = this.newAssessmentObject(pupil);
      } else {
        payload = result;
      }

      switch (criteria.key) {
        case "performance":
          payload.performance = { String: grade.key, Valid: true };
          break;
        case "collaboration":
          payload.collaboration = { String: grade.key, Valid: true };
          break;
        case "competition":
          payload.competition = { String: grade.key, Valid: true };
          break;
      }

      this.processAssessment(result, payload);
    },
    processAssessment(result, payload) {
      if (result != undefined) {
        SchoolService.update("assessments", payload.identifier, payload)
        .catch(
          (err) => {
            this.completion(err);
          }
        );
      } else {
        SchoolService.create("assessments", payload)
          .then((response) => {
            payload.identifier = response.data.identifier;
            this.assessments.push(payload);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style scoped>
</style>